import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ARTICLE_CREATE } from 'Consts/routes';
import { parseQueryToObject } from 'Utils/querystring';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import ArticlesList from 'Components/trainer/articles/List';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';

export default class TrainerBlog extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const { history, location,  } = this.props;
        const queryObject = parseQueryToObject(location.search);

        return (
            <StyledComponent className="page-blog" styles={require('./styles')}>
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Artykuły"
                        tabs={[{
                            key: 'articles',
                            label: 'Twoje artykuły',
                            children: (
                                <ArticlesList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                        controls={[
                            {
                                visible: Boolean(queryObject.tab === 'articles' || !queryObject.tab),
                                key: 'articleCreate',
                                label: 'Nowy wpis',
                                onClick: () => history.push(
                                    withVariables(
                                        TRAINER_ARTICLE_CREATE.path,
                                        {},
                                        {},
                                    )
                                ),
                                style: 'gradient',
                                layout: 'wide',
                            }
                        ]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
