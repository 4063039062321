import { connect } from 'react-redux';
import Component from './component';

import {
    list as listArticles
} from 'Redux/modules/trainer/articles/actions';

export default connect(
    state => ({
        articles: state.trainerArticles.list
    }),
    dispatch => ({
        actions: {
            listArticles: dispatch(listArticles),
        },
    })
)(Component);
